import React from "react"
import Layout from "@components/layout/layout"
import SeoComponents from "@components/seo"
import HeadingBG from "@images/pexels-max-vakhtbovych-6284230.jpeg"

const Newsletter = ({ location }) => {
  const DefaultBG = "" // Provide the path to your default background image

  return (
    <Layout location={location}>
      <SeoComponents
        title="Custom Woodworking Shops Near Me - Join Our Newsletter Today"
        description="For high quality handmade wood furniture, Eden Oaks provides custom woodworking in Colorado Springs and surrounding areas. Call us to discuss your project!"
      />
      <div className="page-heading mb-5">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <h1 className="display-4 text-white">
                Join Our Newsletter Today!
              </h1>
            </div>
          </div>
        </div>
        <div
          className="page-heading-bg"
          style={{
            backgroundImage: `url(${
              HeadingBG !== undefined ? HeadingBG : DefaultBG
            })`,
          }}
        />
      </div>
    </Layout>
  )
}

export default Newsletter
